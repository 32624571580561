<template>
  <div class="defaultStyle delivery">
    <div class="content">
      <div class="maincontent">
        <el-row
          type="flex"
          style="justify-content: space-between; align-items: center"
        >
          <div style="margin-right: 40px">
            <div class="mb10" style="font-size: 16px">
              <b>{{
                lang_data == 1 ? "他人代寄二维码" : "Qr code waiting to be sent"
              }}</b>
            </div>

            {{
              lang_data == 1
                ? "将模型交给其他负责邮件的人员寄送，通过扫描二维码进行下单"
                : "The model will be sent to other people in charge of mail, and the order will be placed by scanning the QR code"
            }}
          </div>
          <div style="margin-left: -200px">
            <img src="@/assets/img/codeico.png" alt="" @click="getCode" />
          </div>
        </el-row>
        <el-row>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span class="subtitle">
              <span class="txt">{{
                lang_data == 1 ? "寄件订单" : "Send n order"
              }}</span>
            </span>
            <div>
              <el-button
                @click="waitingShow = true"
                type="primary"
                size="small"
                icon="el-icon-circle-plus-outline"
                round
                >{{
                  lang_data == 1 ? "添加待寄订单" : "Add a pending order"
                }}</el-button
              >
              <el-button
                type="success"
                size="small"
                round
                @click="$router.push('/freedeliveryList')"
                >{{
                  lang_data == 1
                    ? "已下单快递"
                    : "The order has been placed by express"
                }}
                <i class="el-icon-right"></i
              ></el-button>
            </div>
          </div>

          <div v-for="(item, index) in factorys" class="mt20" :key="index">
            <span style="font-weight: 550; font-size: 15px">{{
              item.factory_name
            }}</span>
            <el-table :data="item.order" border>
              <el-table-column
                label="患者姓名"
                prop="patient_name"
              ></el-table-column>
              <el-table-column
                label="下单码"
                prop="order_code"
              ></el-table-column>
              <el-table-column
                label="状态"
                prop="order_type.value"
              ></el-table-column>
              <el-table-column
                :label="lang_data == 1 ? '操作' : 'Action'"
                width="150px"
              >
                <template slot-scope="scope">
                  <el-link
                    type="danger"
                    @click="del(scope.row.express_free_send_qrcode_id)"
                    >{{ lang_data == 1 ? "删除" : "Delete" }}</el-link
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-button
            class="fr mt30 mb30"
            type="primary"
            size="small"
            round
            @click="confirmOrder"
            v-if="factorys.length > 0"
          >
            {{ lang_data == 1 ? "本人下单" : "I place the order" }}
          </el-button>
        </el-row>
      </div>
      <waitingOrder v-if="false"></waitingOrder>
      <el-dialog
        :title="
          lang_data == 1 ? '他人代寄二维码' : 'Send QR code on behalf of others'
        "
        :visible="QRCodeShow"
        :before-close="cancelCode"
        :close-on-click-modal="false"
        width="300px"
      >
        <div class="qr-code">
          <div id="qrcode"></div>
          <el-button
            class="mt20"
            type="primary"
            size="small"
            round
            @click="isPrint = true"
          >
            {{ lang_data == 1 ? "打印二维码" : "Print QR code" }}
          </el-button>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelCode" size="small">关闭</el-button>
        </span>
      </el-dialog>
      <printTpl
        v-if="isPrint"
        :codeUrl="qrcodeUrl"
        @print-over="isPrint = false"
      ></printTpl>
      <confirmOrder
        v-if="confirmShow"
        :ids="data_ids"
        :confirmShow="confirmShow"
        @cancel-event="close"
        @ok-event="close"
        :qrcode_count="qrcode_count"
      ></confirmOrder>
      <waitingOrder
        v-if="waitingShow"
        :waitingShow="waitingShow"
        @ok-event="close"
        @cancel-event="close"
      ></waitingOrder>
    </div>
  </div>
</template>
<script>
import waitingOrder from "./waitingOrder";
import confirmOrder from "./confirmOrder";
import QRCode from "qrcodejs2";
import printTpl from "./printTpl";
export default {
  name: "delivery",
  components: { waitingOrder, printTpl, confirmOrder },
  mounted() {
    this.userdata = JSON.parse(localStorage.userData);
    this.getlist();
  },
  computed: {
    qrcodeUrl() {
      return (
        "https://xindoctor.xinmk.cn/#/delivery/mobile?doctorId=" +
        this.userdata.doctor_id
      );
    },
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      data_ids: [],
      confirmShow: false,
      waitingShow: false,
      factorys: [],
      qrcode_count: 0,
      userdata: {},
      QRCodeShow: false,
      isPrint: false,
    };
  },
  methods: {
    cancelCode() {
      this.QRCodeShow = false;
    },
    getlist() {
      this.$axios
        .post("/orders/express_free_send_order/getqrcodelist")
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          } else {
            this.factorys = res.data;
          }
        });
    },
    confirmOrder() {
      //本人下单
      this.selectfactory();
      this.qrcode_count = this.data_ids.length;
      if (this.qrcode_count > 0) {
        this.confirmShow = true;
      } else {
        this.$message.error(
          this.lang_data == 1
            ? "请选择要寄送的订单"
            : "Please select the order you want to send"
        );
      }
    },
    selectfactory() {
      this.data_ids = [];
      this.factorys.forEach((item) => {
        this.data_ids = this.data_ids.concat(
          item.order.map((el) => el.express_free_send_qrcode_id)
        );
      });
    },
    appendOrder(list) {
      list.forEach((item) => {
        let factoryItem = this.factorys.filter(
          (el) => el.id == item.factory_id
        )[0];
        if (factoryItem) {
          if (factoryItem.list.filter((el) => el.id == item.id).length > 0) {
            this.$message.error(
              this.lang_data == 1 ? "订单已存在" : "The order already exists."
            );
          } else {
            factoryItem.checked = false;
            factoryItem.list.push(item);
          }
        } else {
          this.factorys.push({
            name: item.factory_name,
            id: item.factory_id,
            list: [item],
          });
        }
      });
      this.close();
    },
    close(refresh) {
      this.waitingShow = false;
      this.confirmShow = false;
      if (refresh) {
        this.getlist();
      }
    },
    del(id) {
      this.$confirm(
        this.lang_data == 1
          ? "确认要删除待寄订单?"
          : "Confirm that you want to delete the pending order?",
        this.lang_data == 1 ? "提示" : "Prompt"
      )
        .then(() => {
          let params = {
            express_free_send_qrcode_id: id,
          };
          this.$axios
            .post("/orders/express_free_send_order/cancelqrcode", params)
            .then((res) => {
              if (res.code == 1) {
                this.$message.success(res.msg);
                this.getlist();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {});
    },
    // 生成二维码标识
    getCode() {
      this.QRCodeShow = true;
      setTimeout(() => {
        document.getElementById("qrcode").innerHTML = "";
        this.generateQRCode();
      });
    },
    generateQRCode() {
      new QRCode("qrcode", {
        width: 200,
        height: 200,
        title: "",
        text: this.qrcodeUrl,
        colorDark: "#000",
        colorLight: "#fff",
      });
      document.getElementById("qrcode").setAttribute("title", "");
    },
  },
};
</script>
<style lang="scss">
.defaultStyle {
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  .qr-code {
    text-align: center;
    img {
      margin: 0 auto;
    }
  }
  .content {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    .maincontent {
      width: 800px;

      .el-row {
        margin-top: 30px;
      }
      .el-table {
        border: 1px solid #ebeef5;
        border-bottom: 0;
        margin-top: 10px;
      }
      .el-table td {
        border-bottom: 1px solid #ebeef5;
        padding: 8px 0;
      }
    }
  }
}
</style>